import React, { useState } from 'react';
import thumb from '../../assets/images/features-thumb-1.png';
import shapeSix from '../../assets/images/shape/shape-6.png';
import shapeSeven from '../../assets/images/shape/shape-7.png';
import shapeEight from '../../assets/images/shape/shape-8.png';

function FeaturesHomeOne({ className }) {
    const [tab, setTab] = useState('setting');
    const handleClick = (e, value) => {
        e.preventDefault();
        setTab(value);
    };
    return (
        <section className={`appie-features-area pb-100 pt-100 ${className}`} id="features">
            <div className="container">
                <div className="row align-items-center">

                    
                    <div >
                        <div className="appie-features-tabs-btn">
                            <div
                                className="nav flex-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                               


                               
                            </div>
                        </div>
                    </div>




                    <div className="col-lg-12">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className={`${
                                    tab === 'setting' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-thumb text-center wow animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img src={thumb} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>Web 3.0 account</span>
                                            <h3 className="title">
                                                It is <br /> Web 3.0 ready
                                            </h3>
                                            <p>
                                                When you signup for Grapez account, Web 3.0 account is automatically created for you. Your tokens and tree NFTs will be kept in your account.   
                                            </p>
                                            {/** 
                                            <Link className="main-btn" to="/about-us">
                                                Learn More
                                            </Link>
                                            */}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 pr-5">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>Your wallet</span>
                                            <h3 className="title">
                                                It is <br /> simple.
                                            </h3>
                                            <p>
                                                You manage your tokens and NFTs in your Web 3.0 account using integrated wallet within the Grapez app. No need to download other wallet or go through complicated setup.
                                            </p>
                                            {/** 
                                            <a className="main-btn" href="#">
                                                Learn More
                                            </a>
                                            */}
                                        </div>
                                    </div>


                                    <div className="col-lg-6 pr-5">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>GRAPEZ token</span>
                                            <h3 className="title">
                                                It is <br /> environment friendly
                                            </h3>
                                            <p>
                                                GRAPEZ token is a local Grapez platform token for user rewards. It is pupose built for two reasons: 
                                                (1) Sustainability - to avoid the energy consumption associated with transactions on public blockchain.
                                                (2) Financial - to reduce the cost of transaction on public blockchain. 
                                                
                                                This is because high volume of transactions are expected when users send token rewards to each other.
                                            </p>
                                            {/** 
                                            <a className="main-btn" href="#">
                                                Learn More
                                            </a>
                                            */}
                                        </div>
                                    </div>


                                    <div className="col-lg-6 pr-5">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>IMPA token</span>
                                            <h3 className="title">
                                                It is <br /> impactful
                                            </h3>
                                            <p>
                                                IMPA token is a platform token that users use to buy tree NFTs. Members can send real tree NFT as a reward to each other. IMPA tokens are required to buy tree NFT on Grapez platform. 
                                                This token is a standard token that is deployed on eco-friendly public blockchain and compatible with any web 3.0 wallet. In the future, depending on the usage volume, this token may be traded on crypto exchanges.  
                                            </p>
                                            {/** 
                                            <a className="main-btn" href="#">
                                                Learn More
                                            </a>
                                            */}
                                        </div>
                                    </div>


                                    <div className="col-lg-6 pr-5">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <span>Tree NFT</span>
                                            <h3 className="title">
                                                It is <br /> all about Sustainability
                                            </h3>
                                            <p>
                                                We made it so simple for users to send real tree NFT to each other. Users can send tree NFT along with their appreciations.
                                                Tree NFT is linked to real tree planted by farmers. Tree NFT owners can virtually travel to the actual tree location and monitor the growth 
                                                and amount of CO<sub>2</sub> removed from the environment. NFT owners can also view their NFT on public NFT marketplaces, such as Opensea.
                                            </p>
                                            {/** 
                                            <a className="main-btn" href="#">
                                                Learn More
                                            </a>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </div>





                           
                           


                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shapeSix} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shapeSeven} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shapeEight} alt="" />
            </div>
        </section>
    );
}

export default FeaturesHomeOne;
