import React from "react";
//import { Typography } from "@material-ui/core";

const PrivacyPolicy = () => {
  return (
    <div className="pl-100 pr-100 pt-50">
      <div className="pb-10">  <h3 className="title">Privacy Policy</h3>   </div>
      
      <div className="pb-10">DATE LAST MODIFIED: Nov 15, 2022</div>
      <div className="pb-10">
        <h5>Importance of Your Privacy.</h5>
      </div>

      <div className="pb-10">
        At LyncChain Technology Ltd., your privacy is of great importance to us.
        We understand that you entrust us with certain private information to
        help us provide various products and services. In exchange for your
        trust, you expect and deserve our commitment to treat your information
        with respect and in accordance with this Privacy Policy. Rest assured
        that we will protect your privacy. Under no circumstances will LyncChain
        Technology Ltd. sell or share any personal information about you to or
        with any person or organization except (i) as authorized by you, (ii) as
        described in the relevant portion of our Website (the "Website"), (iii)
        in connection with providing various products and/or services to you
        (the "Services"), (iv) to the extent you make any use of Grapez's
        mobile software application commonly referred to as "Grapez" or any
        other LyncChain Technology Ltd. mobile application (the "Application"),
        to merchants with which LyncChain Technology Ltd. has a business
        relationship, (v) as may be required by law or court order, or (vi) as
        otherwise set forth herein. The Privacy Policy below describes the
        standards we adhere to in handling information about you and any
        personal information you submit through the Website and/or the
        Application.
      </div>

      <div className="pb-10">
        PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE SUBMITTING ANY
        INFORMATION TO LYNCCHAIN TECHNOLOGY OR THROUGH ANY GRAPEZ SOFTWARE OR
        MOBILE APPLICATION. BY SUBMITTING ANY INFORMATION YOU ARE ACKNOWLEDGING
        THAT YOU HAVE READ AND UNDERSTAND THIS POLICY AND THAT YOU AGREE TO BE
        BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF THIS
        PRIVACY POLICY, SIMPLY EXIT THIS PAGE AND/OR THE APPLICATION WITHOUT
        ACCESSING OR USING ANY OF OUR SERVICES.
      </div>

      <div className="pb-10">
       <h5>A Special Note About Children.</h5> 
      </div>
      <div className="pb-10">
        Children are not eligible to use our Services and we ask that minors
        (under the age of 13) do not submit any personal information to us or
        use the Services. LyncChain Technology. does not collect or maintain
        information from those actually known to be under the age of 13, and no
        part of Grapez's website, the Application or Services is structured to
        attract anyone under the age of 13. Other age restrictions may be set
        forth in Grapez's Terms of Use from time to time.
      </div>

      <div className="pb-10">
        <h5>Important Categories of Information for you to Know</h5>
      </div>
      <div className="pb-10">
        (1). Who we are (2). Information we may collect at the Website or
        through the Mobile Application (3). How your information is used (4).
        Altering the information you submit through the Website and/or
        Application
      </div>

      <div className="pb-10">
        <h6>1. Who We Are.</h6>
      </div>
      <div className="pb-10">
        LyncChain Technology operates a website located at www.grapez.io and
        the Grapez Mobile Application through which members can send appreciations to
        each other.
      </div>
      <div className="pb-10">
       <h6> 2. Information We Collect.</h6>
      </div>
      <div className="pb-10">
        There are several areas on the Website and/or within the Application
        where you may be asked to enter both personal and non-personal
        information including, by way of example and without limitation, your
        name, phone number and email. In addition, when you use the Application
        LyncChain Technology automatically receives and records information on
        our server database from your browser or mobile platform, including your
        IP address and location. LyncChain Technology Ltd. also collects and
        uses additional information from users such as location, Application
        open date and time, idle time, and all clicks and/or swipes within the
        application. LyncChain Technology Ltd. may also collect information
        posted by users on social media sites for users that have opted to
        connect to or otherwise link Grapez with social media accounts
        including Facebook and Twitter. We treat this data as non-personal
        information for purposes of providing the Services, except where we are
        required to treat it as personal information under applicable law. We DO
        NOT sell or share any personal information about you to or with any
        person or organization except (i) as authorized by you, (ii) as set
        forth in the relevant portion of the Website, Application or within any
        agreement between us, (iii) in connection with providing various
        products or Services to you (either directly or through one or more
        third parties), (iv) as may be required by law or court order, or (vi)
        as otherwise set forth herein. In particular, and not in limitation of
        the foregoing, information you enter at the Website and/or Application
        (i) will be shared with the merchants or third party service providers
        with which LyncChain Technology Ltd. has entered into a business
        relationship in order to provide the Services, and (ii) may be shared
        with various third parties in connection with making available to you
        certain offers (which offers may be based on or related to your location
        at the time the offer is made available to you). In addition, LyncChain
        Technology may sell, disclose or otherwise use information gathered on
        the Website or within the Application to third parties on an aggregated
        basis.
      </div>

      <div className="pb-10">
        (a) Information we receive from you. To use LyncChain Technology
        Services and/or to access the Mobile Application, you'll need to provide
        certain personal information which may include, without limitation, your
        name, address, birthday, phone number, credit card information, Facebook
        and/or Twitter user names, e-mail address, information regarding your
        cell phone or mobile device and other information.
      </div>
      <div className="pb-10">
        (b) Technical information we collect. In order for LyncChain Technology
        to ensure that we are providing the best possible consumer experience on
        the Website and within the Mobile Application, we collect and track
        certain user information. When you visit the Website, our Web server may
        send a cookie to your computer. This cookie would be intended to store a
        limited amount of information about your site visit. The cookie tracks
        aggregate information on how our site is being used, including session
        counts, navigation patterns, pages visited, etc. These cookies, however,
        do not store any personally identifiable information such as your name,
        email address or phone number as you browse the site. When you submit a
        question, we store your cookie identification in our system. This cookie
        allows our site to recognize you when you return. LyncChain Technology
        Ltd. may also utilize Web Logs to track aggregate information about how
        our site and/or the Application is being used. Web Logs track anonymous
        user information, including the number of visitors to the Website,
        operating systems, Internet Provider addresses (if publicly available),
        and browser type. Unlike cookies, Web Logs are maintained only on
        LyncChain Technology Ltd. servers and do not store or place anything on
        the user's computer.
      </div>

      <div className="pb-10">
        If you come to the Grapez Website from an online banner ad, an
        anonymous identification number may be passed to the Website. This
        information cannot be used to gather any personally identifiable
        information about you. It will only allow us to track the overall
        effectiveness of our online advertising, marketing and banner
        advertisement campaigns.
      </div>

      <div className="pb-10">
        (c) Other Information we get from your use of the Services. We may
        collect information about the Services that you use and how you use
        them. This information includes:
      </div>

      <div className="pb-10">
        <h6>Device information</h6>
      </div>
      <div className="pb-10">
        We may collect device-specific information (such as your hardware model,
        operating system version, unique device identifiers, and mobile network
        information including phone number).
      </div>

      <div className="pb-10">
        <h6>Location information</h6>
      </div>
      <div className="pb-10">
        When you use the Service or access the Mobile Application, we may
        collect and process information about your actual location. We may also
        use various technologies to determine location, some of which may be
        made available through third party licensors. (d) Information from
        surveys. You may have the option of completing an online survey at the
        Website or within the Mobile Application from time to time.
        Participation in surveys is completely voluntary and it is your choice
        whether or not you wish to disclose your personal information to us in
        connection with any such survey. (e) Information from the "Contact Us"
        section of the Website or Mobile Application. You may contact LyncChain
        Technology through the "Contact Us" feature. Participation in this area
        is completely voluntary and it is your choice whether or not you wish to
        disclose your personal information to us. (f) Information collected in
        other sections of the Website or Application. LyncChain Technology may,
        from time to time, make various resources available to you at the
        Website or within the Mobile Application. At times we may collect
        personal information in connection with providing such services.
      </div>

      <div className="pb-10">
        <h6>3. How Your Information Is Used</h6>
      </div>
      <div className="pb-10">
        We use the information you provide to provide the applicable Services to
        you, either directly or through third party service providers, as
        further described above in this Privacy Policy. LyncChain Technology may
        also share certain aggregated data with other third parties for general
        marketing and demographic purposes; however, this data, when shared on
        an aggregated basis, does not include any of your personal identifying
        information. Some of our Services may be offered in conjunction with
        partner companies, affiliates or other companies or websites with which
        we work. In order for us to provide these Services to you, it may be
        necessary for us to share your personal information with one of such
        entities. These parties are not allowed to use personally identifiable
        information except for the purpose of providing these Services. If you
        do not want your data to be shared, you can choose not to allow the
        transfer by not using that particular Service.
      </div>

      <div className="pb-10">
        In addition, LyncChain Technology Ltd. may disclose account or other
        personal information when we have reason to believe that disclosing this
        information is necessary to identify, contact or bring legal action
        against someone who may be causing injury to or interference with
        LyncChain Technology rights or property, other Website or Mobile
        Application users or anyone else that could be harmed by such
        activities. We may, from time to time, sell leads to third parties that
        we believe might be of interest to you, and such leads may contain
        certain information you have provided to us. Such third parties may use
        such information to contact you with offers and/or other information
        they believe may be of interest to you. If you do not want us to include
        your information in any such sale of leads, you are free to opt out by
        e-mailing us at support@lyncchain.com.
      </div>

      <div className="pb-10">
       <h6> 4. Altering Information You Submit</h6>
      </div>
      <div className="pb-10">
        (a) Deleting your information. Federal and state regulations may require
        that we store questions or submissions for various periods of time. In
        order to comply with these regulations, we may be unable to delete
        information from our databases.
      </div>
      <div className="pb-10">
        (b) Altering Information. LyncChain Technology may alter, modify or
        delete any information you submit to the Website or through the Mobile
        Application if it believes, in its sole discretion, it is necessary or
        desirable to do so.
      </div>
      <div className="pb-10">
        (c) Request to stop receiving emails from us. Periodically, LyncChain
        Technology may send you emails to alert you to various opportunities or
        offers available at the Website or within the Mobile Application. If you
        do not wish to receive any of these offers, please send an e-mail to
        support@lyncchain.com to remove yourself from our mailing list.
      </div>

      <div className="pb-10">
        <h6>Security </h6>
      </div>
      <div className="pb-10">
        LyncChain Technology uses extensive and sophisticated secure technology
        to protect your data and transmissions between you and LyncChain
        Technology. Transmission between browsers and our web server is
        implemented using either Secure Sockets Layer (SSL) technology or
        LyncChain Technology's proprietary mobile application that utilizes
        SSL-encrypted communication. This technology requires an SSL-capable
        browser. Although LyncChain Technology has taken these reasonable and
        appropriate measures to ensure that your personal information is
        delivered and disclosed only in accordance with your instructions,
        LyncChain Technology cannot and does not guarantee that the personal
        information you provide will not be intercepted by others and decrypted.
      </div>

      <div className="pb-10">
        <h6>Changes to Privacy Policy</h6>
      </div>
      <div className="pb-10">
        If we make a change to our Privacy Policy in the future that will affect
        how we treat your information, we will post such change at the Website
        and/or within the Mobile Application, and such change will be deemed to
        be effective 1 days from the date of such posting. In that case, your
        ability to opt out of the new policy will be determined by applicable
        laws.
      </div>

      <div className="pb-10">
        <h6>Business Transfer</h6>
      </div>
      <div className="pb-10">
        We may, in the future, sell or otherwise transfer some or all of our
        assets or equity to a third party. Your personally identifiable
        information and other information we obtain from you via the Website or
        the Mobile Application may be disclosed to any potential or actual third
        party purchasers of such assets or equity and may be among the assets
        transferred.
      </div>

      <div className="pb-10">
        <h6>Acceptance </h6>
      </div>
      <div className="pb-10">
        If you do not agree to the terms of this Privacy Policy, please do not
        provide us with any information and do not use the Website or the Mobile
        Application. By using the Website or the Mobile Application and
        voluntarily providing information to us, you consent to our collection
        and use of the information as set forth in this Privacy Policy.
      </div>

      <div className="pb-10">
        <h6>Links</h6>
      </div>
      <div className="pb-10">
        The Website and/or Application may contain links to other websites or
        applications that are owned and operated by third parties that are not
        affiliated with us. When you use those links, you leave the Website or
        Mobile Application (as applicable) and we are unable to control how the
        owners of the websites or applications you link to handle any
        information they gather about you, including their privacy practices and
        use of cookies. This Privacy Policy applies only to the Website and
        Mobile Application and does not govern activities on linked websites or
        applications. We encourage you to review the privacy policy of any other
        website or application you visit, download or access to understand its
        owner's practices on how it collects, uses and discloses your
        information.
      </div>

      <div className="pb-10">
        <h4>General Site and Mobile Application Terms </h4>
      </div>
      <div className="pb-10">
        These General Website and Mobile Application Terms govern all visits to
        and/or any use of any portion of the website located at www.grapez.io,
        our Grapez mobile application. and/or any affiliated websites. All
        information, images, designs and/or content posted at the Grapez
        website or mobile application from time to time is owned solely and in
        full by LyncChain Technology, and may not be accessed or used in any way
        except to the extent explicitly set forth in these General Website and
        Mobile Application Terms.
      </div>
      <div className="pb-10">
        You may not use any page-scrape, deep-link, robot, crawl, index, spider,
        click spam, macro programs, Internet agent, or other automatic device,
        program, algorithm or methodology which is intended to approximate any
        of the foregoing actions, to use, access, copy, acquire information,
        generate impressions or clicks, input information, store information,
        search, generate searches, or monitor any portion of the Grapez website
        or take any action in respect of merchants and/or other company
        information listed at the Grapez website or mobile application from
        time to time. You may not modify, re-render, frame, mirror, truncate,
        add to, inject, filter or change the order of the information contained
        on any page of the Grapez web pages or any merchant information on any
        other web site or for any purpose without our express written
        permission.
      </div>
      <div className="pb-10">
        You may not copy, display, distribute, download, license, modify,
        publish, reproduce, reuse, sell, transmit, use to create a derivative
        work or otherwise use the content of this website and mobile application
        for public or commercial purposes without our express written
        permission. Nothing on this website or mobile application shall be
        construed to confer any grant or license of any intellectual property
        rights, whether by estoppel, by implication or otherwise.
      </div>
      <div className="pb-10">
        You may not copy, derive, edit, translate, decompile, reverse engineer,
        modify, use, or reproduce any code or source relating to our website or
        mobile application, including without limitation, any service or product
        LyncChain Technology offers.
      </div>
      <div className="pb-10">
        You may not cause to appear any pop-up, pop-under, exit windows,
        expanding buttons, banners advertisement, or anything else which
        minimizes, covers or otherwise inhibits the full display of the Grapez
        website. You may not use the Grapez website in any way which depletes
        web infrastructural resources, slows the transferring or loading of any
        web page or interferes with the normal operation of our website.
      </div>
      <div className="pb-10">
        You may not upload or transmit to the Grapez website or mobile
        application any device, software, program or file that may damage the
        operation of any computer or the Grapez website, including without
        limitation, viruses or corrupt files. You may not disguise the origin of
        information transmitted to, from, or through the Grapez website or
        mobile application. You may not circumvent any measures implemented by
        LyncChain Technology aimed at preventing violations of these General
        Website Terms. You may not violate the restrictions in any robot
        exclusion header.
      </div>

      <div className="pb-10">
        When you use the Grapez website, mobile application or any information
        included therein for an authorized purpose, you must include all
        proprietary notices without changing, hiding or deleting them.
      </div>
      <div className="pb-10">
        LyncChain Technology makes no representations or warranties regarding
        any content or information posted at the Grapez website or mobile
        application, including as to whether or not such content or information
        is accurate.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
