export const getConfigValues = () => {
  const CMS_URL = "https://appreci8-dapp-prod.herokuapp.com/api";
  const USER_PROFILE = "USER_PROFILE";
  const APPRECI8_IMAGE = "APPRECI8_IMAGE";
  const PLATFORM_API_KEY = "1234567890";

  const configValues = {
    CMS_URL: CMS_URL,
    USER_PROFILE: USER_PROFILE,
    APPRECI8_IMAGE: APPRECI8_IMAGE,
    PLATFORM_API_KEY: PLATFORM_API_KEY,
  };
  return configValues;
};
