import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
//import store from "store";
import { activate } from "../helpers/activateAccount";
//import { getUserProfileData } from "../helpers/getUserProfileData";

//import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    //maxWidth: "100%",// 345,
    width: "100%", //  500,
    paddingBottom: 40,
    paddingLeft: "15%", //200,
    paddingRight: "15%", // 200,
    paddingTop: 50,
    //margin: 60
  },
  alignItemsAndJustifyContent1: {
    //width: 500,
    paddingTop: 20,
    //paddingBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //flexDirection: 'column'
    //backgroundColor: '#FF5A60',
  },
  alignItemsAndJustifyContent: {
    //width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 40,
  },
}));

const AccountActivationConfirm = (props) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isUserEmailId, setIsUserEmailId] = useState(true);
  const [isHandleCalled, setIsHandleCalled] = useState(false);
  const [userFirstName, setUserFirstName] = useState();
  const history = useHistory();
  //const alert = useAlert();

  let userId;
  let firstName;

  console.log("props.match =");
  console.log(props.match);

  if (props.match) {
    userId = props.match.params.userId;
    firstName = props.match.params.firstName;
    //console.log(`userId = ${userId}`);
    console.log("userId =",userId);
    console.log("firstName =",firstName);
  }

  /** 
  useEffect(()=> {
    if(userId) {
      getUserProfileData(userId).then((prof)=> {
        setUserFirstName(prof.firstName);
      });
    }
  },[userId]);
*/

  const handleConfirmation = () => {
    console.log("Handle confirmation called ...");
    setIsHandleCalled(true);
    // first get ownerId
    //const ownerIdData = store.get("OwnerData");

    if (userId) {
      setIsUserEmailId(true);
      activate(userId).then((resData)=> {
      console.log("Activate response", resData);
      setIsConfirmed(true);
      });
      
    } else {
      setIsUserEmailId(false);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {!isHandleCalled && (
        <div className={classes.alignItemsAndJustifyContent}>
          <Typography variant="h4" gutterBottom>
            {`Hello ${firstName}. Let's activate your account.`}
          </Typography>
        </div>
      )}

      {isConfirmed && (
        <div className={classes.alignItemsAndJustifyContent}>
          <Typography variant="h5" gutterBottom>
            Thank you for confirming your email.
          </Typography>
        </div>
      )}

      <div className={classes.alignItemsAndJustifyContent1}>
        {!isHandleCalled && (
          <button style={{ borderRadius: 10 }} onClick={handleConfirmation}>
            {" "}
            {"Activate"}
          </button>
        )}

        {!userId && isHandleCalled && (
          <div>
            <div className={classes.alignItemsAndJustifyContent}>
              <Typography variant="h4" gutterBottom>
                Oops! Something went wrong. Please login and try again.
              </Typography>
            </div>

            <button
              onClick={() => {
                //alert.show('Oh look, an alert!');
                history.push("/");
              }}
            >
              OK
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountActivationConfirm;


