
//const CMS_URL = "https://appreci8-dapp-dev.herokuapp.com/api";
import { getConfigValues } from "./getConfigValues";


 export const activate = async (userId) => {

    const config = getConfigValues();
    const CMS_URL = config.CMS_URL;

    let resData;
    let apiKey = "1234567890";

    try{
        const activateRespone = await fetch(`${CMS_URL}/users/activate/${apiKey}`, {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userId: userId
            }),
        });

        if (!activateRespone.ok) {
            let message = "Something went wrong while activating account!";
            //console.log(message);
            throw new Error(message);
        }

            resData = await activateRespone.json();

        } catch(err) {}
        
        return resData;
};